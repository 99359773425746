const size = {
  s: "700px",
  m: "900px",
  l: "1280px",
  xl: "1600px",
}

export const device = {
  s: `(min-width: ${size.s})`,
  m: `(min-width: ${size.m})`,
  l: `(min-width: ${size.l})`,
  xl: `(min-width: ${size.xl})`,
  touch: `(hover: none) and (pointer: coarse)`,
}
